import { jsx } from "react/jsx-runtime";
import { createComponentStore } from "@tecnobit-srl/components-store";
import { Spinner } from "@tecnobit-srl/ui-spinner";
const spinnerContainerStyle = {
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
};
const {
  Component: InnerContent,
  ComponentStoreProvider: RoutableTypesProvider,
  useAddComponent: useAddRoutableType
} = createComponentStore(
  "routable-types",
  /* @__PURE__ */ jsx("div", { style: spinnerContainerStyle, children: /* @__PURE__ */ jsx(Spinner, {}) })
);
function Routable({
  type,
  props
}) {
  return /* @__PURE__ */ jsx(InnerContent, { id: type, p: props });
}
export {
  Routable,
  RoutableTypesProvider,
  useAddRoutableType
};
